import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
// DONT REMOVE REACTPIXEL IMPORT
import ReactPixel from 'react-facebook-pixel';
import Store from './Domain/Redux/Store/Store';
import './App.css';
import { Routes } from './Routes/Routes';
import InitialSetup from './Components/Atoms/InitialSetup/InitialSetup';

const App = () => {
  return (
    <Provider store={Store}>
      <InitialSetup>
        <Routes />
      </InitialSetup>
    </Provider>
  );
};

export default App;
